<template>
  <async-container :loading="firstLoad" fluid>
    <v-card>
      <v-card-title class="title-bar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn color="primary" icon v-on="tooltip" @click="loadData" small>
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
        {{$vuetify.breakpoint.mdAndUp ? 'CARP - Cartão Anual de Regularidade Profissional' : 'CARP'}}
        <v-spacer/>
        <v-btn
          color="primary"
          @click="() => {formCarpReset(); dialogFormAddCarp = true;}"
          small
        >
          <v-icon>mdi-plus</v-icon> ADICIONAR
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="null" :disabled="loading">
          <v-row>
            <v-col cols="12">
              <v-combobox
                label="Fonte"
                multiple
                small-chips
                :items="fontesItems"
                dense
                outlined
                hide-details
                item-value="id"
                item-text="nome"
                v-model="iptFiltroFonte"
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                label="Status"
                multiple
                small-chips
                :items="statusItems"
                dense
                outlined
                hide-details
                item-value="id"
                item-text="nome"
                v-model="iptFiltroStatus"
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Local de entrega"
                :items="[{'id': 0, 'nome': 'TODOS'}, ...entregasItems]"
                item-value="id"
                item-text="nome"
                v-model="iptFiltroEntrega"
                dense
                outlined
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                class="pt-0"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Busque por nome ou CRECI"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
              <v-switch
                label="Não mostrar exercícios anteriores"
                dense
                hide-details
                class="mt-1"
                v-model="iptOcultarExerciciosAnteriores"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-data-table
        :headers="carpHeaders"
        :items="carpItemsFiltered"
        sort-by="data_registro"
        :search="search"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
        :loading="loading"
        sort-desc
        multi-sort
        show-select
        dense
        v-model="selectedRows"
      >
        <template v-slot:item.id="{item}">
          <span class="text-no-wrap">ID {{item.id}}</span>
        </template>
        <template v-slot:item.creci="{item}">
          <v-chip color="primary" outlined small label>{{item.creci}}</v-chip>
        </template>
        <template v-slot:item.fonte_id="{item}">
          {{fontesItems.find(i => i.id === item.fonte_id).nome}}
        </template>
        <template v-slot:item.status_id="{item}">
          <span
            style="font-weight: 500"
            v-bind:style="{color: obterStatusMetadata(item.status_id).color}"
          >{{obterStatusMetadata(item.status_id).nome}}</span>
        </template>
        <template v-slot:item.entrega_id="{item}">
          {{entregasItems.find(i => i.id === item.entrega_id).nome}}
        </template>
        <template v-slot:item.data_registro="{item}">
          {{formatDate(item.data_registro)}}
        </template>
        <template v-slot:item.actions="{item}">
          <div class="d-flex flex-nowrap align-center justify-center">
            <v-badge
              v-if="item.observacoes"
              :content="item.observacoes"
              overlap
              bordered
              left
              color="warning"
            >
              <v-btn
                icon
                small
                color="primary"
                title="Observações"
                @click="loadObservacoes(item.id)"
              >
                <v-icon>mdi-comment-processing</v-icon>
              </v-btn>
            </v-badge>
            <v-btn
              v-else
              icon
              small
              color="primary"
              title="Observações"
              @click="loadObservacoes(item.id)"
            >
              <v-icon>mdi-comment-processing</v-icon>
            </v-btn>

            <v-btn
              icon
              small
              color="purple"
              title="Histórico"
              @click="loadHistorico(item.id)"
            >
              <v-icon>mdi-clock</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <!-- Botões de ação em lote -->
    <v-fab-transition>
      <v-speed-dial
        v-if="showActionButton"
        bottom
        right
        fixed
        open-on-hover
        direction="top"
      >
        <template v-slot:activator>
          <v-btn
            color="indigo"
            dark
            fab
            class="flex-column"
          >
            <div class="d-flex flex-column align-center">
              <v-icon>mdi-checkbox-multiple-marked</v-icon>
              {{selectedRows.length}}
            </div>
          </v-btn>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{on,attrs}">
            <v-btn
              fab
              dark
              small
              color="orange"
              v-bind="attrs"
              v-on="on"
              @click="dialogStatusChanger = true"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </template>
          <span>Alterar status</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{on,attrs}">
            <v-btn
              fab
              dark
              small
              color="success"
              v-bind="attrs"
              v-on="on"
              @click="extrairPlanilha"
            >
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-btn>
          </template>
          <span>Extrair Planilha</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{on,attrs}">
            <v-btn
              fab
              dark
              small
              color="info"
              v-bind="attrs"
              v-on="on"
              @click="downloadArquivosConfeccao"
            >
              <v-icon>mdi-smart-card-reader</v-icon>
            </v-btn>
          </template>
          <span>Arquivo para confecção</span>
        </v-tooltip>
      </v-speed-dial>
    </v-fab-transition>
    <!-- Dialogo de mudança de status -->
    <v-dialog v-model="dialogStatusChanger" width="500" max-width="94%">
      <v-card :loading="loading" @submit.prevent="alterarStatus">
        <v-form>
          <v-card-title class="justify-space-between">
            Alterar status
            <v-btn icon @click="dialogStatusChanger = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div v-if="alertarStatusDiferente">
              <v-alert type="warning">
                Você marcou alguns CARPs com mais de um status diferente, tenha certeza antes de prosseguir.
              </v-alert>
              <v-divider class="my-4"/>
            </div>
            <v-select
              label="Novo status"
              :items="statusItems"
              :rules="obrigatoriedadeRule"
              placeholder="Selecione o status"
              item-text="nome"
              item-value="id"
              v-model="iptStatus"
            ></v-select>
            <v-switch
              label="Avisar os corretores por e-mail"
              class="mt-0"
              hide-details
              v-model="iptAvisarPorEmail"
            />
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" small outlined @click="dialogStatusChanger = false">Cancelar</v-btn>
            <v-btn color="primary" small type="submit">Processar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialogo de carregamento... -->
    <v-dialog v-model="dialogLoading" width="300" max-width="94%" hide-overlay persistent>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          {{loadingDialogMessage}} <v-progress-linear indeterminate color="white" class="mb-0"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogo de adicionar CARP -->
    <v-dialog v-model="dialogFormAddCarp" width="500" max-width="94%">
      <v-card>
        <v-form ref="form-addcarp" @submit.prevent="addCarp">
          <v-card-title class="py-2">Adicionar CARP</v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              v-model="iptCreci"
              label="CRECI"
              hint="Digite o CRECI e pressione ENTER"
              :rules="[...obrigatoriedadeRule, ...creciRules]"
              :success="!!iptCreci.trim() && iptCreci === this.newCarp.creci.toString()"
              @change="buscarDadosDoCorretor"
            />
            <v-text-field v-model="newCarp.cpf" label="CPF" :rules="obrigatoriedadeRule" readonly :success="!!newCarp.cpf"/>
            <v-text-field v-model="newCarp.nome" label="Nome" :rules="obrigatoriedadeRule" readonly :success="!!newCarp.nome"/>
            <v-select
              label="Local de entrega"
              :items="entregasItems"
              item-text="nome"
              item-value="id"
              v-model="newCarp.entrega"
            />
            <v-text-field v-model="newCarp.exercicio" label="Exercicio" :rules="[...obrigatoriedadeRule, ...exercicioRules]"/>
            <v-select
              label="Ocasião"
              :items="[{text: 'Normal', value: 1}, {text: 'Solenidade', value: 2}]"
              v-model="newCarp.fonte"
              hide-details
            ></v-select>
            <v-switch
              v-model="iptAvisarPorEmail"
              label="Avisar o corretor por e-mail"
              hide-details
            />
            <v-alert dense type="error" v-if="pedidoImpedidoInatividade" class="mt-2 mb-0">Este corretor não está ativo</v-alert>
            <v-alert dense type="error" v-else-if="pedidoImpedidoInaptoCertidao" class="mt-2 mb-0">O corretor não possui certidão de regularidade com válidade até o exercício desejado</v-alert>
            <v-alert dense type="error" v-else-if="pedidoImpedidoDuplicidade" class="mt-2 mb-0">Este corretor ja possui pedido de CARP no ano de exercício informado</v-alert>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" type="submit" :disabled="pedidoImpedido || dialogLoading">CONFIRMAR</v-btn>
            <v-btn color="secondary" @click="dialogFormAddCarp = false">CANCELAR</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialogo de histórico de um CARP -->
    <v-dialog v-model="dialogHistorico" width="70rem" max-width="94%">
      <v-card>
        <v-card-title style="border-bottom: 1px solid rgba(0,0,0,.15)" class="purple white--text py-2">
          Histórico
          <v-spacer></v-spacer>
          <v-icon @click="dialogHistorico = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-data-table
          :headers="headersHistorico"
          :items="itemsHistorico"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [-1]}"
          no-data-text="Nenhum registro"
          hide-default-footer
          sort-by="datahora"
        >
          <template v-slot:item.antes="{item}">
            {{statusItems.find(i => i.id === item.antes).nome}}
          </template>
          <template v-slot:item.depois="{item}">
            {{statusItems.find(i => i.id === item.depois).nome}}
          </template>
          <template v-slot:item.datahora="{item}">{{formatDate(item.datahora)}}</template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <!-- Dialogo de Observacoes -->
    <v-dialog v-model="dialogObservacoes" width="64rem" max-width="94%">
      <v-card>
        <v-card-title style="border-bottom: 1px solid rgba(0,0,0,.15)" class="primary white--text py-2">
          Observações
          <v-spacer></v-spacer>
          <v-icon @click="dialogObservacoes = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <p v-if="itemsObservacoes.length === 0" class="text-center px-2 mt-2 mb-0 text-subtitle-1">Nenhuma observação atualmente</p>
        <v-timeline
          v-else
          align-top
          dense
        >
          <v-timeline-item
            v-for="obs in itemsObservacoes" :key="obs.id"
          >
            <v-row class="pt-1" dense no-gutters>
              <v-col cols="12" sm="3">
                <strong>{{formatDate(obs.datahora)}}</strong>
              </v-col>
              <v-col>
                <strong>{{obs.autor_nome}}</strong>
                <div class="text-body-2" style="white-space: pre-wrap; padding-right: .5rem">{{obs.texto}}</div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
        <v-card-text class="pt-3 pb-0">
          <v-form ref="form-observacao" :disabled="loading">
            <v-textarea
              dense
              outlined
              v-model="iptObservacao"
              label="Nova observação"
              rows="2"
              append-outer-icon="mdi-send"
              @click:append-outer="registrarObservacao"
              :rules="obrigatoriedadeRule"
              :loading="loading"
            ></v-textarea>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import CarpWebClient from '@/http/CarpWebClient';
import moment from 'moment';
import I2brWebClient from '@/http/I2brWebClient';
import zipcelx from 'zipcelx';
import Papa from 'papaparse';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import ImageHelper from '@/helpers/ImageHelper';
import StringHelper from '@/helpers/StringHelper';
export default {
  name: 'Carp',
  components: {AsyncContainer},
  data: () => ({
    loading: true,
    firstLoad: true,

    carpItems: [],
    carpHeaders: [
      {text: 'ID', value: 'id', filterable: true},
      {text: 'CRECI', value: 'creci', filterable: true, align: 'center'},
      {text: 'NOME', value: 'nome', filterable: true},
      {text: 'EXERCICIO', value: 'exercicio', filterable: false, align: 'center'},
      {text: 'DATA DO PEDIDO', value: 'data_registro', filterable: false, align: 'center'},
      {text: 'LOCAL DA ENTREGA', value: 'entrega_id', filterable: false, align: 'center'},
      {text: 'PORTAL', value: 'fonte_id', filterable: false, align: 'center'},
      {text: 'STATUS', value: 'status_id', filterable: false, align: 'center'},
      {text: 'AÇÕES', value: 'actions', filterable: false, align: 'center', sortable: false},
    ],

    fontesItems: [],
    statusItems: [],
    entregasItems: [],

    iptFiltroFonte: [],
    iptFiltroStatus: [],
    iptFiltroEntrega: 0,

    //Datatables Config:
    headersHistorico: [
      {value: 'datahora', text: 'Data'},
      {value: 'antes', text: 'Antes'},
      {value: 'depois', text: 'Depois'},
      {value: 'usuario_nome', text: 'Usuario'},
    ],
    itemsHistorico: [],
    itemsObservacoes: [],

    //Dialogs:
    dialogLoading: false,
    dialogFormAddCarp: false,
    dialogStatusChanger: false,
    dialogHistorico: false,
    dialogObservacoes: false,

    loadingDialogMessage: '',

    exercicio: parseInt(moment().format('YYYY')),
    newCarp: null,
    iptCarp: null, //CARP ID
    iptCreci: '',
    iptAvisarPorEmail: false,
    iptObservacao: '',
    iptOcultarExerciciosAnteriores: true,
    iptStatus: null,

    obrigatoriedadeRule: [
      v => !!v || 'Este dado é obrigatório'
    ],
    exercicioRules: [
      v => (v && v.trim().length === 4 && StringHelper.extractNumbers(v).length === v.trim().length) || 'Digite um ano válido para o exercicio'
    ],
    creciRules: [
      v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números'
    ],

    //DataTable values:
    search: '',
    selectedRows: [],
    date: new Date(),
  }),
  methods: {
    formatDate(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    async loadData(lite = true) {
      this.loading = true;
      try {
        const carpWebClient = new CarpWebClient();
        const data = await carpWebClient.getCarps(this.iptOcultarExerciciosAnteriores ? moment().year() : null);
        this.carpItems = data.pedidos;
        if (!lite) {
          this.fontesItems = this.iptFiltroFonte = data.fontes;
          this.statusItems = this.iptFiltroStatus = data.status;
          this.entregasItems = data.entregas;
        }
      } finally {
        this.loading = false;
        this.firstLoad = false;
      }
    },
    async alterarStatus() {
      this.loading = true;
      try {
        const carpWebClient = new CarpWebClient(true);
        const carps = this.selectedRows;
        this.selectedRows = [];
        for (const item of carps) {
          if (item.status_id !== this.iptStatus) {
            const successo = await carpWebClient.updateStatus(item.id, this.iptStatus, this.iptAvisarPorEmail);
            if (successo === false) this.$alert({text: 'Não foi possível notificar por email o corretor ' + item.creci + '/' + item.nome + '.', type: 'warning'})
          }
        }
        await this.loadData();
        this.dialogStatusChanger = false;
      } finally {
        this.loading = false;
      }
    },
    async extrairPlanilha() {
      this.evoqueLoadingDialog('Obtendo dados dos corretores');

      //Para não ser considerado um ataque, não podemos realizar muitas requisições ao mesmo tempo. Faremos a cada 5.
      const i2brWebClient = new I2brWebClient(true);
      let requisitions = [];
      let fichas = [];

      //Ordena por número de creci
      const pedidos = this.selectedRows.sort((a, b) => {
        if (a.creci < b.creci) return -1;
        else if (a.creci > b.creci) return 1;
        else return 0;
      });

      const groups = this.dividirArray(pedidos, 5);
      for (let g of groups) {
        requisitions = g.map(i => i2brWebClient.getFicha(i.cpf));
        fichas = [...fichas, ...await Promise.all(requisitions)];
        await this.sleep(100);
      }

      this.evoqueLoadingDialog('Montando planilha');
      const getLocalDeRetirada = entrega_id => {
        const x = this.entregasItems.find(i => i.id === entrega_id);
        if (!x) return 'ERRO';
        return x.nome;
      };
      const getEnderecoEntrega = (entrega_id, ficha) => {
        switch (entrega_id) {
          case 1:
            return {
              rua: 'RUA ONOFRE PEREIRA DE MATOS, 1458',
              cep: '79802-010',
              bairro: 'CENTRO',
              cidade: 'DOURADOS',
              estado: 'MS',
            };
          case 2:
            return {
              rua: 'RUA ELVIRO MARIO MANCINI, 1301',
              cep: '79602-021',
              bairro: 'JARDIM CANGALHA',
              cidade: 'TRÊS LAGOAS',
              estado: 'MS',
            };
          case 3:
            if (ficha.contato.endereco_correspondencia === 1) return {
              rua: ficha.contato.endereco_residencial.endereco +' '+ ficha.contato.endereco_residencial.complemento,
              cep: ficha.contato.endereco_residencial.cep,
              bairro: ficha.contato.endereco_residencial.bairro,
              cidade: ficha.contato.endereco_residencial.cidade,
              estado: ficha.contato.endereco_residencial.estado,
            };
            else return {
              rua: ficha.contato.endereco_comercial.endereco +' '+ ficha.contato.endereco_comercial.complemento,
              cep: ficha.contato.endereco_comercial.cep,
              bairro: ficha.contato.endereco_comercial.bairro,
              cidade: ficha.contato.endereco_comercial.cidade,
              estado: ficha.contato.endereco_comercial.estado,
            };
          case 4:
            return {
              rua: 'RUA RIO GRANDE DO SUL, 174',
              cep: '79020-010',
              bairro: 'CENTRO',
              cidade: 'CAMPO GRANDE',
              estado: 'MS',
            };
          default:
            return {
              rua: '',
              cep: '',
              bairro: '',
              cidade: '',
              estado: '',
            }
        }
      };
      const header = [
        {type: 'string', value: 'CRECI'},
        {type: 'string', value: 'NOME'},
        {type: 'string', value: 'RETIRADA'},
        {type: 'string', value: 'RUA'},
        {type: 'string', value: 'CEP'},
        {type: 'string', value: 'BAIRRO'},
        {type: 'string', value: 'CIDADE'},
        {type: 'string', value: 'ESTADO'},
        {type: 'string', value: 'LOTE'},
      ];
      const lines = fichas.map((i, index) => {
        return [
          {type: 'string', value: i.dados_gerais.creci.toString()},
          {type: 'string', value: i.dados_gerais.nome},
          {type: 'string', value: getLocalDeRetirada(this.selectedRows[index].entrega_id)},
          {type: 'string', value: getEnderecoEntrega(this.selectedRows[index].entrega_id, i).rua},
          {type: 'string', value: getEnderecoEntrega(this.selectedRows[index].entrega_id, i).cep},
          {type: 'string', value: getEnderecoEntrega(this.selectedRows[index].entrega_id, i).bairro},
          {type: 'string', value: getEnderecoEntrega(this.selectedRows[index].entrega_id, i).cidade},
          {type: 'string', value: getEnderecoEntrega(this.selectedRows[index].entrega_id, i).estado},
          {type: 'string', value: moment().format('DD/MM/YYYY')},
        ];
      });
      this.evoqueLoadingDialog(false);
      zipcelx({
        filename: moment().format('YYYY-MM-DD HH-mm-ss'),
        sheet: {
          data: [header, ...lines]
        },
      });
    },
    async downloadArquivosConfeccao() {
      if (!this.$store.getters['session/checkPermission'](3)) {
        window.alert('Você não possui permissão esta ação. \rSolicite por e-mail ao suporte@crecims.gov.br');
        return;
      }
      this.evoqueLoadingDialog('Obtendo dados dos corretores');

      //Para não ser considerado um ataque, não podemos realizar muitas requisições ao mesmo tempo. Faremos a cada 5.
      const i2brWebClient = new I2brWebClient(true);
      let requisitions = [];
      let fichas = [];

      //Ordena por número de creci
      const pedidos = this.selectedRows.sort((a, b) => {
        if (a.creci < b.creci) return -1;
        else if (a.creci > b.creci) return 1;
        else return 0;
      });

      const groups = this.dividirArray(pedidos, 5);
      for (let g of groups) {
        requisitions = g.map(i => i2brWebClient.getFicha(i.cpf));
        fichas = [...fichas, ...await Promise.all(requisitions)];
        await this.sleep(100);
      }

      const corretores = fichas.map((corretor, index) => {
        let formacao = null;
        switch (corretor.formacao.titulo) {
          case 1:
            formacao = 'GESTOR IMOBILIÁRIO';
            break;
          case 2:
            formacao = 'CORRETOR DE IMÓVEIS';
            break;
          case 3:
            formacao = 'GESTOR IMOBILIÁRIO';
            break;
          case 4:
            formacao = 'GESTOR IMOBILIÁRIO';
            break;
          case 5:
            formacao = 'TÉCNICO EM TRANSAÇÕES IMOBILIÁRIAS';
            break;
          case 7:
            formacao = 'GESTOR IMOBILIÁRIO';
            break;
          case 8:
            formacao = 'GESTOR IMOBILIÁRIO';
            break;
          case 9:
            formacao = 'GESTOR IMOBILIÁRIO';
            break;
          default:
            switch (corretor.info.categoria) {
              case 2:
                formacao = 'TÉCNICO EM TRANSAÇÕES IMOBILIÁRIAS';
                break;
              case 3:
                formacao = 'GESTOR IMOBILIÁRIO';
                break;
              default:
                formacao = 'CORRETOR DE IMÓVEIS';
            }
        }
        return {
          id: corretor.dados_gerais.id,
          nome: corretor.dados_gerais.nome,
          creci: corretor.dados_gerais.creci,
          exercicio: this.selectedRows[index].exercicio,
          vencimento: this.selectedRows[index].exercicio + 1,
          mae: corretor.dados_gerais.mae,
          pai: corretor.dados_gerais.pai,
          rg: corretor.rg.identidade + " " + corretor.rg.orgao_emissor,
          naturalidade: corretor.dados_gerais.naturalidade + "/" + corretor.dados_gerais.uf_naturalidade,
          cpf: corretor.dados_gerais.cpf.substr(0, 3) + '.' + corretor.dados_gerais.cpf.substr(3, 3) + '.' + corretor.dados_gerais.cpf.substr(6, 3) + '-' + corretor.dados_gerais.cpf.substr(9),
          formacao,
          data_inscricao: corretor.dados_gerais.data_inscricao.substr(8, 2) + '/' + corretor.dados_gerais.data_inscricao.substr(5, 2) + '/' + corretor.dados_gerais.data_inscricao.substr(0, 4),
          data_nascimento: corretor.dados_gerais.aniversario.substr(8, 2) + '/' + corretor.dados_gerais.aniversario.substr(5, 2) + '/' + corretor.dados_gerais.aniversario.substr(0, 4),
          data_emissao: moment().format('DD/MM/YYYY'),
          titulo: corretor.dados_gerais.sexo === 2 ? "Corretora de Imóveis" : "Corretor de Imóveis",
          cnai: corretor.cnai.cnai_numero ? 'CNAI '+corretor.cnai.cnai_numero : '',
        };
      });

      try {
        const zip = new JSZip();
        zip.file('carp_lote_' + moment().format('YYYY-MM-DD') + '.csv', new Blob(['\ufeff' + Papa.unparse(corretores, {delimiter: ";"})], {type: 'text/csv'}));

        this.evoqueLoadingDialog('Obtendo fotografias');
        const imagensZip = zip.folder('imagens');
        for (const c of corretores) {
          const formData = new FormData();
          formData.append('cpf', c.cpf);
          formData.append('idregional', '14');
          const req = await Promise.all([
            this.$http.post('https://api.conselho.net.br/corretor/obter/foto.php', formData, {responseType: 'blob', validateStatus: status => status === 200 || status === 404}),
            this.$http.post('https://api.conselho.net.br/corretor/obter/assinatura.php', formData, {responseType: 'blob', validateStatus: status => status === 200 || status === 404})
          ]);
          //Foto
          if (req[0].status === 200) {
            let base64str = await ImageHelper.blobToDataURI_async(req[0].data);
            base64str = base64str.split(';base64,');
            let mime = base64str[0].replace('data:image/', '');
            let foto = base64str[1];
            imagensZip.file(c.creci+"_foto." + mime, foto, {base64: true});
          }
          //Assinatura
          if (req[1].status === 200) {
            let base64str = await ImageHelper.blobToDataURI_async(req[1].data);
            base64str = base64str.split(';base64,');
            let mime = base64str[0].replace('data:image/', '');
            let ass = base64str[1];
            imagensZip.file(c.creci+"_ass." + mime, ass, {base64: true});
          }
        }
        const arquivo = await zip.generateAsync({type: 'blob'});

        FileSaver.saveAs(arquivo, 'carp-lote ' + moment().format('YYYY-MM-DD HH-mm-ss') + '.zip');
      } finally {
        this.evoqueLoadingDialog(false);
      }
    },
    async buscarDadosDoCorretor() {
      if (!this.iptCreci.trim()) {
        this.formCarpReset();
        return;
      }
      this.evoqueLoadingDialog('Buscando corretor');
      const i2brWebClient = new I2brWebClient();
      const resultados = await i2brWebClient.buscarCreci(parseInt(this.iptCreci));
      if (resultados) {
        const dados = resultados.find(i => i.tipo === 1);
        if (!dados) {
          this.$alert({text: 'Nenhum corretor encontrado'});
          this.formCarpReset();
          return;
        }
        const certidao = await i2brWebClient.getCertidao(dados.cpf);
        this.newCarp.creci = parseInt(this.iptCreci);
        this.newCarp.nome = dados.nome;
        this.newCarp.cpf = dados.cpf;
        this.newCarp.ativo = dados.situacao === 1;
        this.newCarp.certidao = certidao;
      }
      await this.$nextTick();
      this.evoqueLoadingDialog(false);
    },
    formCarpReset() {
      const entrega = this.newCarp && this.newCarp.entrega ? this.newCarp.entrega : 4;
      const fonte = this.newCarp && this.newCarp.fonte ? this.newCarp.fonte : 1;
      this.newCarp = {
        cpf: '',
        creci: '',
        nome: '',
        entrega: entrega,
        exercicio: moment().year().toString(),
        fonte: fonte,
        ativo: null,    // Informa se o corretor está ativo durante o processo de add cartão.
        certidao: null, // {apto_certidao: Boolean, validade: StringDatetime}. Valida a aptidão para add cartão.
      };
    },
    async addCarp() {
      if (!this.$refs['form-addcarp'].validate() || this.pedidoImpedido || this.dialogLoading) return;
      const carpWebClient = new CarpWebClient();
      this.evoqueLoadingDialog('Registrando CARP');
      try {
        await carpWebClient.insertCarp(this.newCarp.cpf, parseInt(this.newCarp.creci), this.newCarp.nome, parseInt(this.newCarp.exercicio), this.newCarp.entrega, this.iptAvisarPorEmail, this.newCarp.fonte);
        this.dialogFormAddCarp = false;
        this.evoqueLoadingDialog('Atualizando tabela');
        await this.loadData();
      } finally {
        this.evoqueLoadingDialog(false);
      }
    },
    /**
     * Evoca uma barra horizontal de carregamento no centro da tela.
     * @param {string|boolean} text Texto do loader ou boleano para exibição genérica. false = ocultar.
     */
    evoqueLoadingDialog(text = true) {
      this.loadingDialogMessage = (typeof text === 'string') ? text : 'Carregando';
      this.dialogLoading = !!text;
    },
    dividirArray(array, tamanho = 5) {
      //divide um grande array em partes iguais
      return array.map((e, i) => {
        return i % tamanho === 0 ? array.slice(i, i + tamanho) : null;
      }).filter(e => {
        return e;
      });
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async loadObservacoes(carpId) {
      this.evoqueLoadingDialog('Carregando observações');
      try {
        const carpWebClient = new CarpWebClient();
        this.itemsObservacoes = await carpWebClient.getObservacoes(carpId);
        this.iptCarp = carpId;
        this.dialogObservacoes = true;
      } finally {
        this.evoqueLoadingDialog(false);
      }
    },
    async loadHistorico(carpId) {
      this.evoqueLoadingDialog('Carregando histórico');
      try {
        const carpWebClient = new CarpWebClient();
        this.itemsHistorico = await carpWebClient.getHistorico(carpId);
        this.dialogHistorico = true;
      } finally {
        this.evoqueLoadingDialog(false);
      }
    },
    async registrarObservacao() {
      if (!this.$refs['form-observacao'].validate()) return;
      this.loading = true;
      try {
        const carpWebClient = new CarpWebClient();
        await carpWebClient.insertObservacao(this.iptCarp, this.iptObservacao);
        await this.loadData();
        this.dialogObservacoes = false;
        this.iptObservacao = '';
      } finally {
        this.loading = false;
      }
    },
    obterStatusMetadata(status_id) {
      return this.statusItems.find(i => i.id === status_id);
    },
  },
  created() {
    this.formCarpReset();
    this.loadData(false);
  },
  computed: {
    showActionButton() {
      return this.selectedRows.length > 0;
    },
    carpItemsFiltered() {
      const fontesAceitas = this.iptFiltroFonte.map(i => i.id);
      const statusAceitos = this.iptFiltroStatus.map(i => i.id);
      return this.carpItems.filter(i => fontesAceitas.includes(i.fonte_id) && statusAceitos.includes(i.status_id) && (this.iptFiltroEntrega === 0 || this.iptFiltroEntrega === i.entrega_id));
    },
    alertarStatusDiferente() {
      if (this.selectedRows.length === 0 ) return false;
      const firstStatus = this.selectedRows[0].status_id;
      let conflit = false;
      this.selectedRows.forEach((item) => {
        if (item.status_id !== firstStatus) conflit = true;
      });
      return conflit;
    },
    pedidoImpedidoDuplicidade() {
      //O corretor já possui pedido de carp no ano de exercício solicitado
      return !!this.carpItems.find(i => i.cpf === this.newCarp.cpf && i.exercicio.toString() === this.newCarp.exercicio.toString());
    },
    pedidoImpedidoInatividade() {
      //O corretor está inativo
      return this.newCarp && this.newCarp.ativo === false;
    },
    pedidoImpedidoInaptoCertidao() {
      if (this.newCarp && this.newCarp.exercicio.length === 4 && this.newCarp.certidao) {
        if (!this.newCarp.certidao.apto_certidao) return true;
        else return !!(this.newCarp.certidao.validade && parseInt(this.newCarp.certidao.validade.substr(0, 4)) < (parseInt(this.newCarp.exercicio) + 1));
      }
      else return false;
    },
    pedidoImpedido() {
      if (this.newCarp && this.newCarp.fonte === 2) return this.pedidoImpedidoDuplicidade || this.pedidoImpedidoInatividade;
      return this.pedidoImpedidoDuplicidade || this.pedidoImpedidoInatividade || this.pedidoImpedidoInaptoCertidao;
    }
  },
  watch: {
    iptOcultarExerciciosAnteriores() {
      this.loadData();
    },
    iptCreci(newValue) {
      if (this.newCarp.creci && newValue !== this.newCarp.creci) this.formCarpReset();
    },
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .5rem;
    margin-bottom: 1rem;
  }
</style>
