import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class CarpWebClient {
  http = httpSnackbar;

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  /**
   * Obtem a listagem de CARPs
   * @param {number} exercicio - Ano de exercicio dos cartões
   * @returns {Promise<any>}
   */
  async getCarps(exercicio = null) {
    const {data} = await this.http.get('/carp/listar', {params: {exercicio: exercicio ? exercicio.toString() : undefined}});
    return data;
  }

  /**
   * Registra um CARP.
   * @param {string} cpf CPF do corretor.
   * @param {number} creci Numero de inscricao no CRECI.
   * @param {string} nome Nome completo.
   * @param {number} exercicio Ano de exercicio.
   * @param {0|1|2|3} localEntrega Local da entrega/retirada.
   * @param {boolean} notificar Notifica por email sobre a criação do pedido.
   * @param {number} fonte Identifica o portal e a razão da inserção.
   * @returns {Promise<void>}
   */
  async insertCarp(cpf, creci, nome, exercicio, localEntrega, notificar = true, fonte = 1) {
    const formData = new FormData();
    formData.append('cpf', cpf);
    formData.append('creci', creci.toString());
    formData.append('nome', nome);
    formData.append('exercicio', exercicio.toString());
    formData.append('entrega', localEntrega.toString());
    formData.append('fonte', fonte.toString());
    if (!notificar) formData.append('naonotificar', '1');
    await this.http.post('/carp/inserir', formData);
  }

  /**
   * Altera o status do CARP.
   * @param {number} id - Chave primaria.
   * @param {0|1|2|3|4} novoStatus - Novo status.
   * @param {boolean} notificar - Notifica por email que o status foi alterado.
   * @returns {Promise<boolean>} Indica o sucesso na notificação por email. Null = erro desconhecido.
   */
  async updateStatus(id, novoStatus, notificar = true) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('status', novoStatus.toString());
    if (!notificar) formData.append('naonotificar', '1');
    const response = await this.http.post('/carp/alterar_status', formData, {validateStatus: status => status === 200 || status === 502});
    if (response.status === 200) return true;
    else if (response.status === 502) return false;
    else return null;
  }

  async getHistorico(carpId) {
    const {data} = await this.http.get('/carp/historico?id=' + carpId);
    return data;
  }

  async getObservacoes(carpId) {
    const {data} = await this.http.get('/carp/observacao?id=' + carpId);
    return data;
  }

  /**
   * Insere uma observação.
   * @param {number} carpId
   * @param {string} texto
   * @returns {Promise<void>}
   */
  async insertObservacao(carpId, texto) {
    const formData = new FormData();
    formData.append('carp', carpId.toString());
    formData.append('texto', texto);
    await this.http.post('/carp/observacao', formData);
  }
}
